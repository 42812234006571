export default {
    data() {
        return {
            messageBoxOK: {
                size: "sm",
                buttonSize: "lg",
                centered: true,
                okVariant: "danger",
                footerClass: "p-2",
            },
            messageBoxOKsizeXXL: {
                size: "lg",
                buttonSize: "lg",
                centered: true,
                okVariant: "danger",
                footerClass: "p-2",
            },
            messageBoxAttention: {
                size: "lg",
                buttonSize: "lg",
                centered: true,
                okVariant: "danger",
                footerClass: "p-2",
            },
            messageBoxRegisterOK: {
                size: "sm",
                buttonSize: "lg",
                centered: true,
                okVariant: "danger",
                okTitle: this.$t("button.proceed"),
                footerClass: "p-2",
            },
            messageBoxConfirm: {
                size: "sm",
                buttonSize: "lg",
                centered: true,
                noCloseOnBackdrop: true,
                okVariant: "danger",
                okTitle: this.$t("button.ok"),
                cancelTitle: this.$t("button.cancel"),
                footerClass: "p-2",
            }
        }
    }
}