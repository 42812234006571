<template>
    <div>
        <main-layout> </main-layout>
        <v-container class="wrapper">
            <v-form>
                <h2 class="text-center">Change Password</h2>
                <hr />

                <label class="mt-3">Current password</label>
                <span class="star-require"></span>
                <input class="form-control form-control-submit" v-model="password" type="password" />
                <b-form-invalid-feedback class="pb-3" v-if="saving" :state="isValidPassword">{{ "Please enter a valid password", }}</b-form-invalid-feedback>

                <label class="mt-3">New password</label>
                <span class="star-require"></span>
                <input class="form-control form-control-submit" v-model="newpassword" type="password" />
                <b-form-invalid-feedback class="pb-3" v-if="saving" :state="isValidPassword">{{ "Please enter a valid password", }}</b-form-invalid-feedback>

                <label class="mt-3">Confirm password</label>
                <span class="star-require"></span>
                <input class="form-control form-control-submit" v-model="confirm" type="password" />
                <b-form-invalid-feedback class="pb-3" v-if="saving" :state="isValidPassword">{{ "Please enter a valid password", }}</b-form-invalid-feedback>

                <div class="row mt-5">
                    <div class="col-6">
                        <button class="button--secondary" @click="back">{{ "Go back" }}</button>
                    </div>
                    <div class="col-6">
                        <button class="button--primary button--right" @click="updatePassword">Update</button>
                    </div>
                </div>
            </v-form>
        </v-container>
    </div>
</template>
<script>
// import { mapActions, mapGetters } from "vuex";
import MainLayout from "@/layout/MainLayout.vue";
import screen from "@/mixins/screen.js";
import { mapGetters, mapState } from "vuex";
import popup from "@/mixins/popup.js";
import store from "@/store/index.js";

export default {
    name: "ChangePassword",
    components: {
        MainLayout,
    },
    mixins: [screen, popup],

    data() {
        return {
            loading: false,
            saving: false,
            newpassword: null,
            password: null,
            confirm: null,
        };
    },
    beforeRouteEnter(to, from, next) {
        console.log(store);
        window.scrollTo(0,0);
        store.state.auth.allowAnonymous = false;
        next();
    },
    computed: {
        ...mapState(["auth"]),
        ...mapGetters({
            authDataGet: "auth/authDataGet",
            authToken: "auth/authToken",
        }),
        isValidPassword() {
            return this.password?.length >= 6;
        },
        isValidNewPassword() {
            return this.password?.length >= 6;
        },
        isMatchedPassword() {
            return this.newpassword === this.confirm;
        },
    },
    mounted() {
        this.username = this.authDataGet.phone;
        this.email = this.authDataGet.email ? this.authDataGet.email : "(not registered)";
        this.fullname = this.authDataGet.fullname;
    },

    methods: {
        validate() {
            if (!this.isValidPassword) {
                this.$refs.password.focus();
                return false;
            }
            if (!this.isValidNewPassword) {
                this.$refs.newpassword.focus();
                return false;
            }
            if (!this.isMatchedPassword) {
                this.$refs.confirm.focus();
                return false;
            }

            return true;
        },
        back(e){
            e.preventDefault();
            return this.$router.push("/marketing");
        },
        async updatePassword(e) {
            e.preventDefault();
            this.saving = true;
            const isValid = this.validate();
            if (!isValid) return;
            this.loading = true;

            await this.$store.dispatch("auth/customerUpdatePassword", {
                password: this.password,
                newPassword: this.newpassword,
                newPasswordConfirm: this.confirm,
                new_password: this.newpassword,
            });
            this.loading = false;
            if (this.auth.error) {
                return this.$bvModal.msgBoxOk(
                    "Your password is not valid",
                    this.messageBoxOK
                );
            } else {
                this.$bvModal.msgBoxOk(
                    "Your new password has been set.",
                    this.messageBoxOK
                );
            }
        },
    },
};
</script>